import React from "react"
import styled from "styled-components"
import Layout from "../../components/layout.js"
import SEO from "../../components/seo.js"
import { navigate } from "gatsby"
import { InPageNav } from "../../components/navbar.js"

import { McatHero } from "../../components/sections/heroSection.js"

import TheCatch from "../../components/sections/catch.js"
import {
  ValuePropLeft,
  ValuePropsX3,
  SwipeSection,
} from "../../components/sections/valuePropSection.js"

// import {CtaCard} from '../../components/sections/sections.js';

//SVGs
import VideoSvg from "../../images/video_1.svg"

import WorkSvg from "../../images/work.svg"
import Savings from "../../images/savings.svg"
import Unparalleled from "../../images/unparalleled.svg"
import Guarantee from "../../images/finish.svg"
import LSATSVG from "../../images/judge.svg"

const Ul = styled.ul`
  list-style: none;
  padding: 0;
`
const Li = styled.li`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: 0;
`
const Bullet = styled.svg`
  display: inline-block;
  position: relative;
  top: 5px;
`
const Text = styled.span`
  font-size: 16px;
  &:before {
    content: "";
    display: inline-block;
    margin-right: 0.5em;
  }
`
// const SeeMore = styled.button`
//   display: block;
//   background: white;
//   padding: 5px 16px;
//   border-radius: 30px;
//   height: 15px;
//   margin: 50px auto 50px auto;
//   outline: none;
// `
const List = ({ children }) => {
  return (
    <Li>
      <Bullet
        height="20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="#F50057"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        style={{ display: "inline-block" }}
      >
        <path d="M4 10l4 4 8-9" />
      </Bullet>
      <Text>{children}</Text>
    </Li>
  )
}

// const reviews = [
//   <>
//     I had an amazing experience with 99point9 prep over the summer and extending
//     into the winter with their in-class and online MCAT course. <br /> <br />{" "}
//     -C.G. (515, 128/128/129/130)
//   </>,
//   <span style={{ fontSize: "24px" }}>
//     You have just made my world this summer. And I could never express my
//     gratitude. <br /> -A.M. (Queen's Med.)
//   </span>,
//   <span style={{ fontSize: "13.5px" }}>
//     The MCAT is a confidence test more than anything and the team at 99point9
//     instilled in me the confidence to succeed. They didn't blink when I set a
//     goal of 520, and this led me to believe I could reach even higher than that.
//     The Chem/Phys training was by far the most useful of any free or paid
//     program out there, as the team understands that it's application of
//     knowledge that leads to success in the MCAT. If you're feeling overwhelmed
//     by the entire MCAT process and just want to focus your efforts (for less
//     than a summer's income), I highly reccomend 99point9. <br />{" "}
//     <span style={{ fontSize: "22px" }}>-J.B. (524)</span>
//   </span>,
//   <>
//     I thought Sohaib's teaching style was great for the following reasons. He is
//     very interactive with the class; encouraging everyone to participate rather
//     than him going over the passages himself... <br /> -S.H. (516)
//   </>,

//   <span style={{ fontSize: "15px" }}>
//     99point9 helped me succeed on my MCAT. There is NO other prep course I'd
//     recommend. They personalize everything to help you achieve the best possible
//     score on your MCAT and are with you every step of the way. All the
//     instructors are highly qualified and become an amazing support system
//     throughout your MCAT journey. I have gotten into an Ontario Medical School
//     after taking this prep course. Thank you for everything you've done to help
//     me get there.
//     <br /> <span style={{ fontSize: "22px" }}>-J.M. (Ottawa Med.)</span>
//   </span>,
// ]

const LSAT = ({ location }) => {
  const RedirectToPage = dest => {
    navigate(dest)
  }

  return (
    <Layout>
      <SEO title="LSAT Test Prep." />
      <InPageNav
        currentPath={location.pathname}
        pageTitle="LSAT"
        navItems={[
          { link: "/lsat", label: "Overview" },
          { link: "/lsat/how_it_works", label: "How It Works" },
          { link: "/lsat/schedule", label: "Class Schedule" },
          { link: "/lsat/pricing", label: "Pricing" },
        ]}
        clickCallback={RedirectToPage}
      />
      <McatHero
        title="LSAT"
        body={[
          `Students prep. with us, so they get accepted to better law schools sooner and easier.`,
          <br />,
          <br />,
          `We guarantee you a HIGH score by aggressively tackling your problems, keep you accountable, give you phenomenal content, and help you avoid all possible mistakes.`,
        ]}
        ctaPrimary={{ label: "Buy Course", redirect: "/lsat/schedule/" }}
        ctaSecondary={{ label: "Request Demo", redirect: "/contact" }}
        img={LSATSVG}
        imgWidth="450px"
        backgroundColor="inherit"
      />

      <ValuePropLeft
        caption={"Ultimate"}
        title={"Curricula"}
        description={[
          `Using the LSAC practice tests as the benchmark, we: `,
          <Ul>
            <List>Built our content from scratch</List>
            <List>Prioritized content that gets high scores</List>
            <List>Build your ability to reason</List>
            <List>Teach you to navigate difficult passages</List>
          </Ul>,

          `Given this, we've seen that our students do great on all LSAT passages - no passage or question is too hard if you do it right, and that some have scored as high as 178, using solely our content.`,
        ]}
        figure="Higher Score Ceiling"
      />

      <ValuePropLeft
        caption={"Proven"}
        title={"Track Record"}
        figure={"90%"}
        description={[
          `We're very thorough in our approach. So, it's not surprising when we see our students: `,
          <Ul>
            <List>Jumping from 10/25 right to 25/25, on LR</List>
            <List>Writing the LSAT within 30 to 60 days</List>
            <List>Scoring 165 - 175 on the official LSAT</List>
            <List>
              Scoring high enough to apply to Harvard, Yale, and Stanford
            </List>
          </Ul>,
        ]}
      />

      <ValuePropLeft
        caption={"Tailored"}
        title={"Content Questions"}
        figure={"7000+"}
        description={[
          ` Before we get to practice tests, we've created 7000+ specific questions that:`,
          <Ul>
            <List>Check every part of your content knowledge.</List>
            <List>Assess your ability to reason.</List>
            <List>Build your ability to avoid common trap answers.</List>
            <List>Introduce you to the "hard" LSAT language.</List>
            <List>Teach you how to deal with uncertainty.</List>
          </Ul>,
        ]}
      />
      <TheCatch />
      <SwipeSection
        wide
        float={"center"}
        title={["Course Perks"]}
        asides={[
          {
            title: `More than "worth it"`,
            src: Savings,
            alt: "Schedule Image",
            description:
              "Not long ago, we were students too; and we searched for affordable options! We've integrated that sense into our core philosophy: For what you spend, we promise that value you'll get more bang for your buck!",
          },
          {
            title: "Low-GPA? We've got hope!",
            src: Unparalleled,
            alt: "Review Sheet Image",
          },
          {
            title: "Content: We've got you covered!",
            src: VideoSvg,
            alt: "Notes Image",
            description:
              "While we give more content than anyone in the industry, what's more important is that you don't just",
          },
          {
            title: "Write once and do great!",
            src: Guarantee,
            alt: "Review Sheet Image",
            description:
              "We're Canada's most vocal test prep. company on writing when you're ready.",
          },
          {
            title: "Online & In-class Options",
            src: WorkSvg,
            alt: "Schedule Image",
            description:
              "We offer both online and in-person courses, and options that combine both. Note: During the COVID-19 pandemic, we have limited our offerings to online classes only.",
          },
        ]}
      />

      <ValuePropLeft
        caption={" "}
        title={[
          "165+ Score ",
          <span style={{ color: "#F50057" }}>Guarantee</span>,
        ]}
        figure="CERTAINTY"
        description={`We're confident in our ability to get you scoring well, enough that if you've worked hard and followed our instructions, but didn't do well on the test -- you can retake the course, free of charge!`}
      />

      <ValuePropLeft
        caption={" "}
        title={[
          "You Can: ",
          <span style={{ color: "#F50057" }}>Aim For 170+ </span>,
        ]}
        figure="VISION"
        description={[
          "You have to believe that scoring well on this test is not difficult, and that a 170+, is hard but more than possible!",
          <br />,
          <br />,
          "But, if it's possible then why doesn't everyone do it? Because, students don't know how, or they don't do it. But, that's what we're here to fix.",
          <br />,
          <br />,
          "Plus, if you truly aim for 170+ and work for it, but fall short, you'll still score ~165+. Aim lower, and suddenly you can't apply to med. schools.",
        ]}
      />
      <ValuePropLeft
        caption={" "}
        title={[
          "Think ",
          <span style={{ color: "#F50057" }}>BIG</span>,
          " picture",
        ]}
        figure="ADMISSIONS"
        description={[
          "Here's a secret: if you throw out all the marketing crap, and look how prep. companies work - they're in it for you to score well. Why? Because, better score means better reviews which is good for business - not that there's anything wrong with that.",
          <br />,
          <span style={{ color: "#F50057" }}>#realtalk</span>,
          <br />,
          <br />,
          "But, that's not how we do things. We actually want you to get into med. and as fast as possible. That's why we want you scoring as high as possible, the first time around. That's why we check in on you, push you, celebrate with you, and think of you all the time.",
          <br />,
          <span style={{ color: "#F50057" }}>#ridetogether #dietogether</span>,
        ]}
      />
      <ValuePropLeft
        caption={" "}
        title={[
          `"Study like `,
          <span style={{ color: "#F50057" }}>you're a law student</span>,
          `, starting now"`,
        ]}
        figure="MINDSET"
        description={[
          "We want you to get your head in the game - and attitude matters. That's why, if you're down, we teach you this course with the intensity of law school.",
          <br />,
          <br />,
          "Why? Because, you're going to become a law student anyway, and in the event that you were to later find out that law school wasn't the career for you, this is an easy way to find out without going through the hassle.",
          <br />,
          <br />,
          "What's dope though? Our ex-students report that in their first year of med., while everyone is freaked out about their course load, they feel pretty chill.",
        ]}
      />
      <ValuePropLeft
        caption={" "}
        title={[
          <span style={{ color: "#F50057" }}>No Chance</span>,
          " To Fail",
        ]}
        figure="SONAR-LIKE DETECTION"
        description={[
          "We know the LSATs insanely well, and we know student-struggles really well too! That's why when you're prepping with us, you're not just getting content, but you're also getting instructors who steer you away from trap-habits and trap-actions.",
          <br />,
          <br />,
          "But what does that mean, though? Often, during our prep. you'll want to do different things: try some schedule, want to take a practice test, or maybe take some time off - we can tell you whether it's a good idea, and if it is - then the right time and right approach to these things so your study-momentum doesn't break, and you come out on the top! ",
          <br />,
          <br />,
          "Because sometimes, just having the content and the practice tests isn't enough.",
        ]}
      />
      <ValuePropsX3
        float={"left"}
        wide
        // style={{ margin: "0 auto", paddingTop: "0px", paddingBottom: "200px" }}
        asides={[
          {
            title: [
              <span style={{ color: "#F50057" }}>Reasoning-based</span>,
              " Prep.",
            ],
            description:
              "We teach you how to read better, reason stronger, and find answers faster from all types of passages. When you're ready, you'll know what the passage is before you've finished your first sentence",
          },
          {
            title: [
              <span style={{ color: "#F50057" }}>Bottom-up</span>,
              " Learning",
            ],
            description:
              "You don't need to take philosophy or related university courses for the LSAT; our content is designed to teach you everything from scratch. You'd be surprised -- even high school students have aced the LSAT with us.",
          },
          {
            title: [
              <span style={{ color: "#F50057" }}>LSAC-Only</span>,
              " Practice",
            ],
            description:
              "Imagine preparing for a university exam by studying materials from a different course -- yep, not a good idea! That's why we only questions designed  ",
          },
        ]}
      />
    </Layout>
  )
}

export default LSAT
